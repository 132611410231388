body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
}
